// Librairies principales
import { createApp } from "vue";
import App from "./App.vue";
import store from "@shared/store";
import api from "@shared/services/api";
import i18n from "@shared/i18n";
import * as Router from "@shared/router";

import AppPageLayout from "@patient/components/pages/AppPageLayout.vue";
import createMyVuetify from "./plugins/vuetify";

import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);

// Plugins

// https://github.com/paul-thebaud/v-phone-input
import "flag-icons/css/flag-icons.min.css";
import "v-phone-input/dist/v-phone-input.css";
import { VAutocomplete } from "vuetify/components";

import { createVPhoneInput } from "v-phone-input";

const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
  // True if you want to enable searching country
  enableSearchingCountry: false,
});

// https://swiperjs.com/element
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();


// Note : I'm experimenting a lot with Vuetify, so this is a mess, yeah ....
// We will clean this later, it's no big deal

// Styles
import "@shared/assets/scss/main.scss"; // <= CSS partagé entre toutes les apps
import "@patient/assets/scss/main.scss"; // <= CSS par défaut de l'app patient
import "@customer/app-patient/assets/scss/main.scss"; // <= CSS surchargé pour le client, pour l'app patient

// Imports de composants un peu en vrac (mais en async), peut être amélio aussi (?)
import sharedComponents from "@shared/components/imports.js";
import customerSharedComponents from "@customer/shared/components/imports.js";
import appComponents from "@patient/components/imports.js";
import appCustomerComponents from "@customer/app-patient/components/imports.js";


// Bootstrap de l'app :
// - Chargement de la partie locale de la conf (settings propres à cette app par rapport à la base commune)
// - Initialisation de l'API
// - Chargement de la partie BO de la conf (routing, etc)
// - Chargement des composants hypokrat importés
// - Initialisation de VueJS

// Chargement de la partie locale de la conf (settings propres à cette app par rapport à la base commune)
// + environnement => BO_URL, etc
// Remplis donc les stores de baseConfig ET envConfig
store
  .dispatch("appp/initConfig", {
    appName: "patient",
    targetUserType: "patient",
  })
  .then(() => {
    // Initialisation de l'API (config de Axios, withCredentials, etc)
    api.initApi();

    // Chargement de la partie BO de la conf (routing, etc)
    store.dispatch("app/initBOConfig").then((config) => {
      store.dispatch("app/initI18n");

      //AS--> Inject https://www.bugherd.com/sidebarv2.js?apikey=dxrpwbcpa3rtwtkxuskzrq the async script in the head of the document
      if(config.config?.extra?.bugherd_apikey){
        const script = document.createElement('script');
        script.src   = 'https://www.bugherd.com/sidebarv2.js?apikey='+config.config.extra.bugherd_apikey;
        script.async = true;
        document.head.appendChild(script);
      }

      // @todo => intégration entre getBOTheme et le theme de vuetify, beaucoup de choses possibles ici !
      // @todo => virer aussi ce code dans un fichier à part

      const vuetify = createMyVuetify(store);

      const app = createApp(App);

      const router = Router.init(AppPageLayout);

      // Chargement des composants hypokrat importés.
      sharedComponents.import(app);
      customerSharedComponents.import(app);
      appComponents.import(app);
      appCustomerComponents.import(app);

      // Boum !
      app
        .component("VAutocomplete", VAutocomplete)
        .use(store)
        .use(router)
        .use(i18n)
        .use(vuetify)
        .use(vPhoneInput)
        .mount("#app");

      // Register components for later use
      store.dispatch("app/registerComponents", app._context.components);
    });
  });
