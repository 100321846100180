<template>
  <div
    id="menu"
    class="d-flex justify-space-around bg-white pa-3 main-navigation"
  >
    <template :key="menu.code" v-for="menu in getMenu">
      <router-link
        v-if="menu.path"
        :to="menu.path"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
      >
        <a
          :href="href"
          @click="navigate"
          :class="[
            'text-decoration-none text-caption d-flex flex-column align-center justify-content-center flex-1-1-100',
            (isActive
              ? 'text-black router-link-active'
              : 'text-grey-darken-1') ||
              (isSubActive[href]
                ? 'text-black router-link-active'
                : 'text-grey-darken-1'),
            isExactActive
              ? 'text-black router-link-exact-active'
              : 'text-grey-darken-1',
          ]"
        >
          <component
            v-if="menu?.menu?.icon"
            :is="menu?.menu?.icon?.name"
            class="mb-1"
            size="3"
          />
          {{ $t(menu.menu.label) }}
        </a>
      </router-link>
      <a v-if="menu.action" @click="$store.dispatch(menu.action, menu.params)">
        <v-icon v-if="menu?.menu?.icon" :icon="menu.menu.icon" />
        {{ $t(menu.menu.label) }}
      </a>
    </template>
    <a class="text-decoration-none text-caption d-flex flex-column align-center justify-content-center flex-1-1-100" @click.prevent="debug" v-if="isLocalhost">
      <span :class="{'debug_actif': $store.state.ui.debug}">
        <v-icon icon="mdi-bug" class="mb-1" size="40" />
        Debug
      </span>
    </a>
  </div>
</template>

<script src="./Menu.js"></script>
<style lang="scss" scoped>
.drop-shadow {
  box-shadow: 0px -3px 20px 0px rgba(0, 0, 0, 0.05);
}
.main-navigation {
  .text-caption {
    font-size: 0.625rem !important;
    font-weight: 500;
    line-height: 1;
  }
}
</style>
