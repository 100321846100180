import cloneDeep from "lodash/cloneDeep";
import HomeIcon from "@patient/components/elements/icons/HomeIcon.vue";
import MedkitIcon from "@patient/components/elements/icons/MedkitIcon.vue";
import QuestionIcon from "@patient/components/elements/icons/QuestionIcon.vue";
import ShoppingCartIcon from "@patient/components/elements/icons/ShoppingCartIcon.vue";
import { Capacitor } from '@capacitor/core';

export default {
  name: "Menu",
  data() {
    return {
      isSubActive: {},
    };
  },
  components: {
    HomeIcon,
    MedkitIcon,
    QuestionIcon,
    ShoppingCartIcon,
  },
  methods: {
    logout() {
      try {
        this.$store.dispatch("auth/logout");
      }
      catch (e) {
        window.reload();
      }
      window.reload();
    },
    setIsSubActive(path) {
      // If you need to add sub-routes : routes that should add the "active class" to their "parent" item in the menu,
      // add rules here
      // path will be the "menu" path, and this.$route.fullPath the current full path.
      // Set here rules that returns "true" (and then, add the "active class to the parent) when necessary
      // if (path == '/patients' && this.$route.fullPath.substr(0, 9) == '/patient/') this.isSubActive[path] = true;

      this.isSubActive[path] = false;
    },
    debug() {
      console.log("Routing : ", cloneDeep(this.$router.getRoutes()));
      console.log("Routing, matched :", this.$route);
      console.log("Store : ", cloneDeep(this.$store.state));

      //AS--> Active le debug dans le store
      this.$store.dispatch("ui/toggleDebug");
    },
  },
  computed: {
    isLocalhost() {
      return window.location.href.includes("localhost") && !Capacitor.isNativePlatform();
    },
    getMenu() {
      let self = this;
      return this.$store.getters.getBORoutes.filter(function (m) {
        self.setIsSubActive(m.path);
        let access = true;

        return "menu" in m && access;
      });
    },
  },
  watch: {
    "$route.fullPath"() {
      let self = this;
      Object.keys(this.isSubActive).forEach(function (path) {
        self.setIsSubActive(path);
      });
    },
  },
};
